import http from '@/axios'
const ATTENDEE_API = `${process.env.VUE_APP_LEADS_API}/attendees`

export const state = {}

export const mutations = {}

export const getters = {}

export const actions = {
  createGuest ({}, params) {
    const url = `${ATTENDEE_API}/${params.attendee_id}/guests?account_id=${params.accountId}`

    return http
      .post(url, { guest: params.guest }, { withCredentials: true })
      .then(response => response.data)
  },

  updateGuest ({}, params) {
    const url = `${ATTENDEE_API}/${params.attendee_id}/guests/${params.guest._id}?account_id=${params.accountId}`

    return http
      .put(url, { guest: params.guest }, { withCredentials: true })
      .then(response => response.data)
  },

  deleteGuest ({}, params) {
    const url = `${ATTENDEE_API}/${params.attendee_id}/guests/${params.guest._id}?account_id=${params.accountId}`

    return http
      .delete(url, { withCredentials: true })
      .then(_ => {})
  }
}

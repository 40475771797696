import http from '@/axios'
const CHANNELS_API = `${process.env.VUE_APP_CAMPAIGNS_API}/v1/channels`

const RequiredFields = [
  'id',
  'account_id',
  'sub_account_id',
  'type',
  'price',
  'approved',
  'phone',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination.page = data.page
  },

  SET_CHANNELS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total_count
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchChannels (_, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${ctx.page || 1}`,
      `per_page=${ctx.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${CHANNELS_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  loadChannels ({state, commit, dispatch}, ctx) {
    const context = {
      fields: RequiredFields,
      page: state.pagination.page,
      per_page: state.pagination.per_page,
      params: ctx
    }

    dispatch('fetchChannels', context)
      .then(data => commit('SET_CHANNELS', data))
  },

  fetchChannel ({}, id) {
    return http
      .get(`${CHANNELS_API}/${id}`, { withCredentials: true })
      .then(response => response.data)
  },

  createChannel ({}, ctx) {
    return http
      .post(`${CHANNELS_API}`, { account_id: ctx.account_id, channel: ctx.channel }, { withCredentials: true })
      .then(response => response.data)
  },

  updateChannel ({}, ctx) {
    return http
      .post(`${CHANNELS_API}/${ctx.channel_id}`, { account_id: ctx.account_id, channel: ctx.channel }, { withCredentials: true })
      .then(response => response.data)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

import http from '@/axios'
import { compileToFunctions } from 'vue-template-compiler'
const CAMPAIGNS_API = process.env.VUE_APP_CAMPAIGNS_API

const RequiredFields = [
  'id',
  'name',
  'objective',
  'status',
  'payment_status',
  'start_date',
  'end_date',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination.page = data.page
  },

  SET_CAMPAIGNS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total_count
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchCampaigns ({rootState, state, commit}, ctx) {
    const baseUrl = CAMPAIGNS_API
    const queryParams = [
      `fields=${RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${state.pagination.per_page || 25}`
    ]
    if (ctx.filter.keywords) {
      queryParams.push(`&keywords=${ctx.filter.keywords}`)
    }
    if (ctx.filter.constant) {
      queryParams.push(ctx.filter.constant)
    }

    const url = `${baseUrl}/campaigns?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => { commit('SET_CAMPAIGNS', response.data) })
      .catch(err => console.log('error', err))
  },

  fetchCampaign ({rootState, state, commit}, ctx) {
    const queryParams = []
    if (ctx.accountId) {
      queryParams.push(`account_id=${ctx.accountId}`)
    }

    if (ctx.fields) {
      queryParams.push(`fields=${ctx.fields.join(',')}`)
    }
    
    const url = `${CAMPAIGNS_API}/v1/campaigns/${ctx.campaignId}?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => { return response.data })
      .catch(err => console.log('error', err))
  },

  fetchChannels ({rootState, state, commit}, ctx) {
    const baseUrl = CAMPAIGNS_API
    const queryParams = []
    for (const k in ctx) {
      queryParams.push(`${k}=${ctx[k]}`)
    }

    const url = `${baseUrl}/channels?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

import http from '@/axios'
const NOTES_API = `${process.env.VUE_APP_NOTES_API}/v1/notes`

export const actions = {
  fetchNotes ({}, ctx = {}) {
    const queryParams = [
      `page=${ctx.page || 1}`,
      `per_page=${ctx.per_page || 25}`
    ]
    const params = ctx.params || {}
    for (const [key, val] of Object.entries(params)) {
      queryParams.push(`${key}=${val}`)
    }

    const url = `${NOTES_API}?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  fetchNote ({}, ctx = {}) {
    let url = `${NOTES_API}/${ctx.id}`

    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  createNote ({}, ctx = {}) {
    const params = { account_id: ctx.accountId, note: ctx.note }

    return http
      .post(NOTES_API, params, { withCredentials: true })
      .then(response => response.data)
  },

  updateNote ({}, ctx = {}) {
    const params = { account_id: ctx.accountId, note: ctx.note }
    const url = `${NOTES_API}/${ctx.id}`

    return http
      .put(url, params, { withCredentials: true })
      .then(response => response.data)
  }
}

import http from '@/axios'
const NOTIFICATIONS_API = `${process.env.VUE_APP_NOTIFICATIONS_API}/v1`

export const state = {}

export const mutations = {}

export const actions = {
  fetchSetting ({}, ctx) {
    const url = `${NOTIFICATIONS_API}/accounts/${ctx.accountId}/campaigns/${ctx.campaignId}/campaign_setting`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  updateSetting ({}, ctx) {
    const url = `${NOTIFICATIONS_API}/accounts/${ctx.accountId}/campaigns/${ctx.campaignId}/campaign_setting`

    return http
      .put(url, { campaign_setting: ctx.params }, { withCredentials: true })
      .then(response => response.data)
  }
}

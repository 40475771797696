import http from '@/axios'
const INTEGRATIONS_API = `${process.env.VUE_APP_INTEGRATIONS_API}/v1`

export const actions = {
  fetchSettings ({}, ctx) {
    const queryParams = []

    if (ctx.accountId) {
      queryParams.push(`account_id=${ctx.accountId}`)
    }

    const url = `${INTEGRATIONS_API}/settings?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  createSetting ({}, ctx) {
    const params = { setting: ctx.params }
    const url = `${INTEGRATIONS_API}/settings/${ctx.settingId}`

    return http
      .post(url, params, { withCredentials: true })
      .then(response => response.data)
  },

  updateSetting ({}, ctx) {
    const params = { setting: ctx.params }
    const url = `${INTEGRATIONS_API}/settings/${ctx.settingId}`

    return http
      .put(url, params, { withCredentials: true })
      .then(response => response.data)
  },

  deleteSetting ({}, ctx) {
    const url = `${INTEGRATIONS_API}/settings/${ctx.settingId}`

    return http
      .delete(url, { withCredentials: true })
      .then(response => response.data)
  }
}
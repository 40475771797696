import http from '@/axios'
const SETTING_API = `${process.env.VUE_APP_NOTIFICATIONS_API}/v1/setting`

export const state = {}

export const mutations = {}

export const actions = {
  fetchSetting ({}, ctx) {
    let url = `${SETTING_API}/${ctx.class}/${ctx.id}`
    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  updateSetting ({}, ctx) {
    let url = `${SETTING_API}/${ctx.class}/${ctx.id}`
    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }

    return http
      .put(url, { setting: { settings: ctx.params } }, { withCredentials: true })
      .then(response => response.data)
  }
}

export const routes =  {
  path: '/prospects',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: '',
      name: 'attendees-index',
      component: () => import('@/views/prospects/attendees/Index.vue')
    },
    {
      path: ':id',
      name: 'attendees-show',
      component: () => import('@/views/prospects/attendees/show/Base.vue')
    }
  ]
}
// axios
import axios from 'axios'
import store from '@/store/store'
import router from './router'

const baseURL = 'https://api.loodra.local'

const headers = {
  common: {
    'Content-Type': 'application/json'
  },
  get: {
    'Content-Type': 'application/json'
  }
}

const axiosInstance = axios.create({
  baseURL,
  headers
})

// Try to use refresh token to reauth
// On success return true
// On failure, logout user
const isAuthPath = (url) => {
  const authPaths = ['authentication']
  const path = url
    .split('/')
    .pop()
  return authPaths.includes(path)
}

const routePrefix = () => {
  try {
    return router
      .history
      .current
      .path
      .split('/')
      .filter(seg => seg.length > 0)
      .shift()
  } catch {
    return null
  }
}

const authRedirect = () => {
  if (routePrefix() === 'management') {
    router.push('/auth/management/login')
  } else {
    router.push('/auth/login')
  }
}

const refreshToken = (config) => {
  store
    .dispatch('fetchMe')
    .then(_ => axios.request(config))
    .catch(_ => authRedirect(config))
}

const errorHandler = error => {
  const path = error.config.url.split('/').pop()
  if (error.response && error.response.status === 401) {
    if (path === 'authentication') {
      Promise.reject(error)
    } else if (path.substring(0, 2) === 'me') {
      authRedirect()
    } else {
      return refreshToken(error.config)
    }
  }
}

axiosInstance.interceptors.response.use(
  response => response,
  error => errorHandler(error)
)

axiosInstance.defaults.headers.get['Content-Type'] = 'application/json'

export default axiosInstance

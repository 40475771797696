export const routes =  {
  path: '/settings',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: 'account',
      component: () => import('@/views/settings/account/show/Show.vue')
    }
  ]
}

import http from '@/axios'
const LEADS_API = `${process.env.VUE_APP_LEADS_API}/leads`

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  }
}

export const getters = {}

export const actions = {
  fetchLeads ({}, ctx = {}) {
    const queryParams = [
      `page=${ctx.page || state.pagination.page || 1}`,
      `per_page=${ctx.per_page || state.pagination.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    if (ctx.fields) {
      queryParams.push(`fields=${ctx.fields}`)
    }

    const url = `${LEADS_API}?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  }
}

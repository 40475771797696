import http from '@/axios'
const ACCOUNTS_API = `${process.env.VUE_APP_ACCOUNTS_API}/v1/accounts`

const RequiredFields = [
  'id',
  'name',
  'reseller_id',
  'domain',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_ACCOUNTS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchAccounts ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${state.pagination.per_page || 25}`
    ]

    for (const key in ctx) {
      if (ctx[key]) {
        queryParams.push(`${key}=${ctx[key]}`)
      }
    }

    const url = `${ACCOUNTS_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => { commit('SET_ACCOUNTS', response.data) })
      .catch(err => console.error(err))
  },

  fetchAccount ({}, id) {
    const url = `${ACCOUNTS_API}/${id}`
    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  updateAdmin ({}, ctx) {
    const url = `${ACCOUNTS_API}/${ctx.attendee_id}`
    const params = { attendee: ctx.params }

    return http
      .put(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

import http from '@/axios'
const ADMINS_API = `${process.env.VUE_APP_ACCOUNTS_API}/v1/admins`

const RequiredFields = [
  'id',
  'name',
  'phone',
  'domain',
  'status',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_ADMINS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchAdmins ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${state.pagination.per_page || 25}`
    ]

    for (const key in ctx) {
      if (ctx[key]) {
        queryParams.push(`${key}=${ctx[key]}`)
      }
    }

    const url = `${ADMINS_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => { commit('SET_ADMINS', response.data) })
      .catch(err => console.error(err))
  },

  fetchAdmin ({}, id) {
    const url = `${ADMINS_API}/${id}`
    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  createAdmin ({}, data) {
    return http
      .post(ADMINS_API, data, { withCredentials: true })
      .then(response => response.data)
  },

  updateAdmin ({}, ctx) {
    const url = `${ADMINS_API}/${ctx.admin_id}`
    const params = { admin: ctx.params, reseller_id: ctx.params.reseller_id }

    return http
      .put(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
      .catch(err => err)
  },

  destroyAdmin ({}, ctx) {
    const url = `${ADMINS_API}/${ctx.admin_id}?reseller_id=${ctx.reseller_id}`

    return http
      .delete(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => err)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

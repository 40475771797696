import http from '@/axios'
const INTEGRATIONS_API = `${process.env.VUE_APP_INTEGRATIONS_API}/v1`

export const actions = {
  fetchCommunities ({}, ctx) {
    const queryParams = []

    if (ctx.apiToken) {
      queryParams.push(`api_token=${ctx.apiToken}`)
    }

    const url = `${INTEGRATIONS_API}/integrations/welcome_home/communities?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  createSetting ({}, ctx) {
    const url = `${INTEGRATIONS_API}/integrations/welcome_home`

    return http
      .post(url, ctx.params, { withCredentials: true })
      .then(response => response.data)
  },

  updateSetting ({}, ctx) {
    const url = `${INTEGRATIONS_API}/integrations/welcome_home/${ctx.settingId}`

    return http
      .put(url, ctx.params, { withCredentials: true })
      .then(response => response.data)
  }
}
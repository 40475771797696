import Vue from 'vue'

const timeFormat = function (value, zone = null) {
  const options = { timeZoneName: 'short' }
  if (zone) {
    options['timeZone'] = zone
  }
  const time = new Date(value)
    .toLocaleString('en-US', options)
    .split(',')[1]
    .trim()
    .split(' ')
  return `${time[0]} ${time[1]}`
}

const dateFormat = function (value, zone = null) {
  const options = { timeZoneName: 'short' }
  if (zone) {
    options['timeZone'] = zone
  }

  return new Date(value)
    .toLocaleString('en-US', options)
    .split(',')[0]
}

const timeZone = function (zone = null) {
  const options = { timeZoneName: 'short' }
  if (zone) {
    options['timeZone'] = zone
  }

  return new Date()
    .toLocaleString('en-US', options)
    .split(' ')[3]
}

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  const arr = value.split(' ')
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('truncate', function (value, limit) {
  return value.substring(0, limit)
})

Vue.filter('tailing', function (value, tail) {
  return value + tail
})

Vue.filter('datetimeLocal', function (value, zone = null) {
  return `${dateFormat(value, zone)} ${timeFormat(value, zone)} ${timeZone(zone)}`
})

Vue.filter('timeLocal', function (value, zone = null) {
  return `${timeFormat(value, zone)} ${timeZone(zone)}`
})

Vue.filter('datetime', function (value, zone = null) {
  return `${dateFormat(value, zone)} ${timeFormat(value, zone)} ${timeZone(zone)}`
})

Vue.filter('time', timeFormat)

Vue.filter('date', dateFormat)

Vue.filter('month', function (val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(val)[1]
  } else {
    return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
  }

})

Vue.filter('csv', function (value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
})

Vue.filter('k_formatter', function (num) {
  return num > 999 ? `${(num / 1000).toFixed(1)}k` : num
})

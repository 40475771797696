import http from '@/axios'
const CAMPAIGNS_API = `${process.env.VUE_APP_CAMPAIGNS_API}/v1`

const RequiredFields = [
  'id',
  'name',
  'objective',
  'status',
  'payment_status',
  'start_date',
  'end_date',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination.page = data.page
  },

  SET_CAMPAIGNS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total_count
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchCampaigns ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${ctx.page || 1}`,
      `per_page=${ctx.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${CAMPAIGNS_API}/campaigns?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  loadCampaigns ({rootState, state, commit, dispatch}, ctx) {
    const context = {
      fields: ctx.fields || RequiredFields,
      page: state.pagination.page,
      per_page: state.pagination.per_page,
      params: ctx
    }

    return dispatch('fetchCampaigns', context)
      .then(data => {
        commit('SET_CAMPAIGNS', data)
        return data
      })
  },

  fetchCampaign (_, ctx) {
    const queryParams = []

    if (ctx.accountId) {
      queryParams.push(`account_id=${ctx.accountId}`)
    }

    if (ctx.fields) {
      queryParams.push(`fields=${ctx.fields.join(',')}`)
    }

    const url = `${CAMPAIGNS_API}/campaigns/${ctx.campaignId}?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => { return response.data })
      .catch(err => console.log('error', err))
  },

  fetchChannels (_, ctx) {
    const baseUrl = CAMPAIGNS_API
    const queryParams = []
    for (const k in ctx) {
      queryParams.push(`${k}=${ctx[k]}`)
    }

    const url = `${baseUrl}/channels?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response)
  },

  createCampaign ({}, ctx) {
    return http
      .post(`${CAMPAIGNS_API}/campaigns`, ctx, { withCredentials: true })
      .then(resp => { resp.data })
  },

  updateCampaign ({}, ctx) {
    const url = `${CAMPAIGNS_API}/campaigns/${ctx.campaign_id}${ctx.account_id ? `?account_id=${ctx.account_id}` : ''}`

    const params = {
      campaign: ctx.campaign
    }

    return http
      .put(`${url}`, params, { withCredentials: true })
      .then(response => response)
  },

  destroyCampaign ({}, ctx) {
    const url = `${CAMPAIGNS_API}/campaigns/${ctx.campaign_id}${ctx.account_id ? `?account_id=${ctx.account_id}` : ''}`
    return http
      .delete(url, { withCredentials: true })
      .then(response => response.data)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

import http from '@/axios'
const RESELLERS_API = `${process.env.VUE_APP_ACCOUNTS_API}/v1/resellers`

const RequiredFields = [
  'id',
  'name',
  'phone',
  'domain',
  'status',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_RESELLERS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  getResellers ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${ctx.page || 1}`,
      `per_page=${ctx.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${RESELLERS_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  fetchResellers ({rootState, state, commit, dispatch}, ctx) {
    const context = {
      fields: RequiredFields,
      page: state.pagination.page,
      per_page: state.pagination.per_page,
      params: ctx
    }

    dispatch('getResellers', context)
      .then(data => commit('SET_RESELLERS', data))
  },

  fetchReseller ({}, id) {
    const url = `${RESELLERS_API}/${id}`
    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  updateReseller ({}, ctx) {
    const url = `${RESELLERS_API}/${ctx.reseller_id}`
    const params = { reseller: ctx.params }

    return http
      .put(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
  },

  getUploadUrl ({}, reseller_id) {
    const url = `${RESELLERS_API}/${reseller_id}/upload_url`
    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

const resellerRoutes = {
  path: 'resellers',
  component: () => import('../../layouts/main/Management.vue'),
  children: [
    {
      path: '',
      name: 'resellers-index',
      component: () => import('@/views/management/resellers/Index.vue')
    },
    {
      path: ':id',
      name: 'resellers-show',
      component: () => import('@/views/management/resellers/Show.vue')
    },
    {
      path: ':id/edit',
      name: 'resellers-edit',
      component: () => import('@/views/management/resellers/reseller-edit/ResellerEdit.vue')
    }
  ]
}

const orgRoutes = {
  path: 'organizations',
  component: () => import('../../layouts/main/Management.vue'),
  children: [
    {
      path: '',
      name: 'resellers-index',
      component: () => import('@/views/management/organizations/Index.vue')
    },
    {
      path: ':organization_id',
      component: () => import('../../layouts/main/Blank.vue'),
      children: [
        {
          path: '',
          name: 'organizations-show',
          component: () => import('@/views/management/organizations/Show.vue')
        }
      ]
    }
  ]
}

const accountRoutes = {
  path: 'accounts',
  component: () => import('../../layouts/main/Management.vue'),
  children: [
    {
      path: '',
      name: 'accounts-index',
      component: () => import('@/views/management/accounts/Index.vue')
    },
    {
      path: ':account_id',
      component: () => import('../../layouts/main/Blank.vue'),
      children: [
        {
          path: '',
          name: 'accounts-show',
          component: () => import('@/views/management/accounts/Show.vue')
        },
        {
          path: 'campaigns',
          component: () => import('../../layouts/main/Blank.vue'),
          children: [
            {
              path: '',
              name: 'campaigns-index',
              component: () => import('@/views/campaigns/Index.vue')
            },
            {
              path: ':campaign_id',
              name: 'campaigns-id',
              component: () => import('../../layouts/main/Blank.vue'),
              children: [
                {
                  path: '',
                  name: 'campaigns-show',
                  component: () => import('@/views/campaigns/view/View.vue')
                },
                {
                  path: 'analytics',
                  name: 'campaign-analytics',
                  component: () => import('@/views/pages/Analytics.vue')
                }
              ]
            }
          ]
        },
        {
          path: 'prospects',
          component: () => import('../../layouts/main/Blank.vue'),
          children: [
            {
              path: '',
              name: 'management-attendees-index',
              component: () => import('@/views/prospects/attendees/Index.vue')
            },
            {
              path: ':id',
              name: 'management-attendees-show',
              component: () => import('@/views/prospects/attendees/show/Base.vue')
            }
          ]
        },
        {
          path: 'users',
          component: () => import('../../layouts/main/Blank.vue'),
          children: [
            {
              path: ':user_id',
              name: 'management-users-show',
              component: () => import('@/views/accounts/users/show/UserSettings.vue')
            }
          ]
        }
      ]
    }
  ]
}

const dashboardRoute = {
  path: '',
  component: () => import('../../layouts/main/Management.vue'),
  children: [
    {
      path: '',
      name: 'management-dashboard',
      component: () => import('@/views/management/dashboard/Index.vue')
    }
  ]
}

export const routes =  {
  path: '/management',
  component: () => import('../../layouts/full-page/FullPage.vue'),
  children: [
    accountRoutes,
    dashboardRoute,
    resellerRoutes,
    orgRoutes
  ]
}
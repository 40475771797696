import http from '@/axios'
const LEADS_API = `${process.env.VUE_APP_LEADS_API}`

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_APPOINTMENTS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total_count
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchAppointments ({}, ctx) {
    const queryParams = []

    for (const key in ctx.params) {
      queryParams.push(`${key}=${ctx.params[key]}`)
    }

    if (ctx.accountId) {
      queryParams.push(`account_id=${ctx.accountId}`)
    }

    const url = `${LEADS_API}/appointments?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  createAppointment ({}, ctx) {
    const params = { appointment: ctx.params }
    const url = `${LEADS_API}/attendees/${ctx.attendeeId}/appointments`

    return http
      .post(url, params, { withCredentials: true })
      .then(response => response.data)
  },

  updateAppointment ({}, ctx) {
    const params = { appointment: ctx.params }
    const url = `${LEADS_API}/attendees/${ctx.attendeeId}/appointments/${ctx.params._id}`

    return http
      .put(url, params, { withCredentials: true })
      .then(response => response.data)
  },

  deleteAppointment ({}, ctx) {
    const url = `${LEADS_API}/attendees/${ctx.attendeeId}/appointments/${ctx.appointmentId}`

    return http
      .delete(url, { withCredentials: true })
      .then(response => response.data)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  },

  touchData ({state, commit}) {
    commit('SET_APPOINTMENTS', state.data)
  }
}

import http from '@/axios'
const USERS_API = `${process.env.VUE_APP_ACCOUNTS_API}/v1/users`

export const state = {
  users: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_USERS (state, data) {
    state.users = data.data
    state.pagination.total_count = data.total
  }
}

export const actions = {
  fetchUsers ({}, ctx) {
    const queryParams = [
      `page=${ctx.page || 1}`,
      `per_page=${ctx.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    return http
      .get(`${USERS_API}?${queryParams.join('&')}`, { withCredentials: true })
      .then(response => response.data)
  },

  loadUsers ({state, commit, dispatch}, ctx) {
    const context = {
      page: state.pagination.page || 1,
      per_page: state.pagination.per_page || 25,
      params: ctx.params || {}
    }

    context.params.account_id = ctx.account_id
    if (ctx.organization_id) {
      context.params.organization_id = ctx.organization_id
    }

    dispatch('fetchUsers', context)
      .then(data => commit('SET_USERS', data))
  },

  fetchUser ({}, ctx) {
    let url = `${USERS_API}/${ctx.userId}`
    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  createUser ({}, ctx) {
    return http
      .post(`${USERS_API}`, { user: ctx.user, account_id: ctx.accountId }, { withCredentials: true })
      .then(response => response.data)
  },

  updateUser ({}, ctx) {
    return http
      .put(`${USERS_API}/${ctx.userId}`, { user: ctx.params, account_id: ctx.accountId }, { withCredentials: true })
      .then(response => response.data)
  },

  updatePassword ({}, ctx) {
    return http
      .put(`${USERS_API}/${ctx.userId}/update_password`, { account_id: ctx.accountId, user: ctx.params }, { withCredentials: true })
      .then(response => response.data)
  },

  deleteUser ({}, ctx) {
    return http
      .delete(`${USERS_API}/${ctx.userId}?account_id=${ctx.accountId}`, { withCredentials: true })
      .then(response => response.data)
  },

  fetchLinkedAccounts ({}, ctx) {
    return http
      .get(`${USERS_API}/${ctx.userId}/accounts?account_id=${ctx.accountId}`, { withCredentials: true })
      .then(response => response.data)
  },

  updateLinkedAccounts ({}, ctx) {
    return http
      .post(`${USERS_API}/${ctx.userId}/account_access?account_id=${ctx.accountId}`, ctx.params, { withCredentials: true })
      .then(response => response.data)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

import http from '@/axios'
const ORGANIZATIONS_API = `${process.env.VUE_APP_ACCOUNTS_API}/v1/organizations`

const RequiredFields = [
  'id',
  'name',
  'reseller_id',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_ORGANIZATIONS (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchOrganizations ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${state.pagination.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${ORGANIZATIONS_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => {
        commit('SET_ORGANIZATIONS', response.data)
        return response.data
      })
  },

  createOrganization ({}, data) {
    return http
      .post(ORGANIZATIONS_API, data, { withCredentials: true })
      .then(response => response.data)
  },

  fetchOrganization ({}, id) {
    const url = `${ORGANIZATIONS_API}/${id}`

    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  updateOrganization ({}, ctx) {
    const url = `${ORGANIZATIONS_API}/${ctx.organization_id}`

    return http
      .put(url, { account: ctx.params }, { withCredentials: true })
      .then(response => response.data)
      .catch(err => err)
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  }
}

const userRoutes = {
  path: 'users',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: '',
      name: 'users-index',
      component: () => import('@/views/accounts/users/Index.vue')
    },
    {
      path: ':user_id',
      name: 'users-show',
      component: () => import('@/views/accounts/users/show/UserSettings.vue')
    }
  ]
}
const subAccountRoutes = {
  path: 'sub-accounts',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: '',
      name: 'subaccounts-index',
      component: () => import('@/views/accounts/subaccounts/Index.vue')
    }
  ]
}

const linkedAccountsRoutes = {
  path: 'linked-accounts',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: '',
      name: 'lined-accounts-index',
      component: () => import('@/views/accounts/linkedAccounts/Index.vue')
    }
  ]
}

export const routes =  {
  path: '/accounts',
  component: () => import('../../layouts/full-page/FullPage.vue'),
  children: [
    userRoutes,
    subAccountRoutes,
    linkedAccountsRoutes
  ]
}

import http from '@/axios'
const ACCOUNTS_API = process.env.VUE_APP_ACCOUNTS_API

export const state = {
  loaded: false,
  accounts: [],
  currentAccount: null,
  currentUser: null,
  currentAdmin: null,
  currentReseller: null,
  currentOrganization: null,
  userAccounts: []
}

export const mutations = {
  SET_CURRENT_USER (state, newValue) {
    state.loaded = true
    state.currentUser = newValue.user
    state.currentOrganization = newValue.organization
    state.currentAccount = newValue.account
    state.currentAdmin = newValue.admin
    state.currentReseller = newValue.reseller
    state.userAccounts = newValue.user_accounts
    saveState(state)
  }
}

export const actions = {
  authenticate ({ commit }, { username, password, management, account_id } = {}) {
    delete http.defaults.headers['X-API-TOKEN']
    if(management) {
      window.localStorage.removeItem('current_account_id')
    }
    return http
      .post(`${ACCOUNTS_API}/authentication`, { username, password, management, account_id }, { withCredentials: true })
      .then(response => {
        commit('SET_CURRENT_USER', response.data)
      })
  },

  fetchMe ({ commit }, ctx = {}) {
    let url = `${ACCOUNTS_API}/me`
    const current_account_id = ctx.accountId || window.localStorage.getItem('current_account_id')
    if (current_account_id) {
      url = `${url}?account_id=${current_account_id}`
    }

    return http
      .get(url, { withCredentials: true })
      .then(response => { commit('SET_CURRENT_USER', response.data) })
  },

  logOut ({ commit }, ctx = {}) {
    const url = `${ACCOUNTS_API}/logout`

    return http
      .get(url, { withCredentials: true })
      .then(_ => { commit('SET_CURRENT_USER', {}) })
  },

  passwordReset ({_}, email) {
    return http
      .post(`${ACCOUNTS_API}/auth/password_reset`, { email })
      .then(response => { response.data })
  },

  resetPassword ({_}, { username, new_password, confirm_password, password_key } = {}) {
    return http
      .post(`${ACCOUNTS_API}/auth/reset_password`, { username, new_password, confirm_password, password_key })
      .then(response => { response.data })
  },

  users () {
    return http
      .get(`${ACCOUNTS_API}/users`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.log('error', err))
  },

  user ({_state}, id) {
    return http
      .get(`${ACCOUNTS_API}/users/${id}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.log('error', err))
  },

  updateUser ({_state}, { id, data }) {
    return http
      .put(`${ACCOUNTS_API}/users/${id}`, { user: data }, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.log('error', err))
  },

  verifyContact ({_state}, { id, type, code }) {
    return http
      .post(`${ACCOUNTS_API}/users/${id}/verify_contact`, { type, code }, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.log('error', err))
  },

  subAccounts () {
    return http
      .get(`${ACCOUNTS_API}/sub_accounts`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.log('error', err))
  },

  assumeUser ({ commit }, ctx = {}) {
    const queryParams = [
      `account_id=${ctx.accountId}`,
      `user_id=${ctx.userId}`
    ]

    const url = `${ACCOUNTS_API}/assume?${queryParams.join('&')}`

    return http
      .get(url, { withCredentials: true })
      .then(response => { commit('SET_CURRENT_USER', response.data) })
  }
}

// Private helpers
function getSavedState () {
  return window.localStorage.getItem('current_account_id')
}

function saveState (state) {
  if (state.currentAccount) {
    window.localStorage.setItem('current_account_id', state.currentAccount.id)
  }
}

import http from '@/axios'
const CAMPAIGNS_API = `${process.env.VUE_APP_CAMPAIGNS_API}/v1/campaigns`
const OBJECTIVES_API = `${process.env.VUE_APP_CAMPAIGNS_API}/v1/objectives`

export const state = {}

export const mutations = {}

export const getters = {}

export const actions = {
  fetchObjectives ({}, ctx) {
    const queryParams = []
    if (ctx.campaign_ids) {
      queryParams.push(`campaign_ids=${ctx.campaign_ids.join(',')}`)
    } else if (ctx.objective_ids) {
      queryParams.push(`objective_ids=${ctx.objective_ids.join(',')}`)
    }

    if (ctx.account_id) {
      queryParams.push(`account_id=${ctx.account_id}`)
    }

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    if (ctx.fields) {
      queryParams.push(`fields=${ctx.fields.join(',')}`)
    } 

    const url = `${OBJECTIVES_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  fetchObjective ({}, ctx) {
    return http
      .get(`${OBJECTIVES_API}/${ctx.objectiveId}`, { withCredentials: true })
      .then(response => response.data)
  },

  createObjective ({}, ctx) {
    return http
      .post(`${CAMPAIGNS_API}/${ctx.campaign_id}/objectives`, ctx, { withCredentials: true })
      .then(resp => { resp.data })
  },

  updateObjective ({}, ctx) {
    return http
      .put(`${CAMPAIGNS_API}/${ctx.campaign_id}/objectives/${ctx.objective_id}`, ctx, { withCredentials: true })
      .then(resp => { resp.data })
  }
}
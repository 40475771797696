import http from '@/axios'
const ATTENDEE_API = `${process.env.VUE_APP_LEADS_API}/attendees`
const ATTENDEE_API_V1 = `${process.env.VUE_APP_LEADS_API}/v1/attendees`

const RequiredFields = [
  '_id',
  'account_id',
  'campaign_id',
  'objective_id',
  'channel_id',
  'advertisement_id',
  'first_name',
  'last_name',
  'email',
  'guests',
  'phone',
  'status',
  'address',
  'created_at',
  'updated_at'
].join(',')

export const state = {
  data: [],
  sort: {
    field: 'created_at',
    dir: 'asc'
  },
  pagination: {
    page: 1,
    per_page: 25,
    total_count: 0
  }
}

export const mutations = {
  SET_PAGINATION (state, data) {
    state.pagination = data
  },

  SET_ATTENDEES (state, data) {
    state.data = data.data
    state.pagination.total_count = data.total_count
  }
}

export const getters = {
  data (state) {
    return state.data
  }
}

export const actions = {
  fetchAttendees ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${state.pagination.per_page || 25}`
    ]

    for (const key in ctx) {
      if (ctx[key]) {
        queryParams.push(`${key}=${ctx[key]}`)
      }
    }

    const url = `${ATTENDEE_API_V1}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => { commit('SET_ATTENDEES', response.data) })
      .catch(err => console.error(err))
  },

  loadAttendees ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${state.pagination.per_page || 25}`
    ]

    for (const key in ctx) {
      if (ctx[key]) {
        queryParams.push(`${key}=${ctx[key]}`)
      }
    }

    const url = `${ATTENDEE_API_V1}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  getAttendees ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${ctx.per_page || state.pagination.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${ATTENDEE_API_V1}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  getAttendeesPdf ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${ctx.per_page || state.pagination.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${ATTENDEE_API_V1}/name_tags?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  getAttendeesCsv ({rootState, state, commit}, ctx) {
    const queryParams = [
      `fields=${ctx.fields || RequiredFields}`,
      `page=${state.pagination.page || 1}`,
      `per_page=${ctx.per_page || state.pagination.per_page || 25}`
    ]

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams.push(`${key}=${ctx.params[key]}`)
      }
    }

    const url = `${ATTENDEE_API_V1}/csv?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
      .catch(err => console.error(err))
  },

  fetchAttendee ({}, ctx) {
    let url = `${ATTENDEE_API_V1}/${ctx.id}`
    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }
    return http
      .get(url, { withCredentials: true })
      .then(response => response.data)
  },

  createAttendee ({}, ctx) {
    const params = { attendee: ctx.params }

    return http
      .post(ATTENDEE_API, params, { withCredentials: true })
      .then(response => response.data)
  },

  updateAttendee ({}, ctx) {
    const url = `${ATTENDEE_API}/${ctx.attendee_id}?account_id=${ctx.accountId}`
    const params = { attendee: ctx.params }

    return http
      .put(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
  },

  destroyAttendee ({}, ctx) {
    let url = `${ATTENDEE_API}/${ctx.attendeeId}`
    
    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }

    return http
      .delete(`${url}`, { withCredentials: true })
      .then(response => response.data)
  },

  fetchCampaign (_, id) {
    return http
      .get(`${ATTENDEE_API}/${id}`, { withCredentials: true })
      .then(response => { return response.data })
      .catch(err => console.error(err))
  },

  fetchAppointments ({}, ctx) {
    let url = `${ATTENDEE_API}/${ctx.attendeeId}/appointments`

    if (ctx.accountId) {
      url += `?account_id=${ctx.accountId}`
    }

    return http
      .get(url, { withCredentials: true })
      .then(response => { return response.data })
      .catch(err => console.error(err))
  },

  paginationUpdate ({state, commit}, pagination) {
    commit('SET_PAGINATION', { ...state.pagination, ...pagination })
  },

  touchData ({state, commit}) {
    commit('SET_ATTENDEES', state.data)
  }
}

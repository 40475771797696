/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

// Route Modules
import { routes as management_routes } from '@/router/routes/management'
import { routes as campaign_routes } from '@/router/routes/campaigns'
import { routes as auth_routes } from '@/router/routes/auth'
import { routes as accounts_routes } from '@/router/routes/accounts'
import { routes as prospects_routes } from '@/router/routes/prospects'
import { routes as events_routes } from '@/router/routes/events'
import { routes as settings_routes } from '@/router/routes/settings'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    management_routes,
    auth_routes,
    accounts_routes,
    campaign_routes,
    prospects_routes,
    events_routes,
    settings_routes,
    {
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@/views/Dashboard.vue'),
          meta: {
            beforeResolve (_routeTo, _routeFrom, next) {
              next()
            }
          }
        }
      ]
    },
    {
      path: '/pages',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: 'error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Dont guard auth routes or if user is logged in
  if (to.path.includes('auth')) return next()
  if (store.state.accounts.loaded) return next()
  
  // Try to login
  store
    .dispatch('accounts/fetchMe')
    .then(_ => {
      const managementRoute = to.path.includes('management')
      let currentUser = store.state.accounts.currentUser
      if (managementRoute) {
        currentUser = store.state.accounts.currentAdmin
      }
      if (currentUser) {
        next()
      } else {
        const routeName = managementRoute ? 'management-login' : 'login'
        return next({ name: routeName })
      }
    })
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

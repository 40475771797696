import http from '@/axios'
const CAMPAIGNS_API = `${process.env.VUE_APP_CAMPAIGNS_API}/v1/campaigns`

export const state = {
}

export const mutations = {
}

export const getters = {
}

export const actions = {
  fetchContent ({}, ctx) {
    return http
      .get(`${CAMPAIGNS_API}/${ctx.campaignId}/content`, { withCredentials: true })
      .then(resp => resp.data)
  },

  updateContent ({}, ctx) {
    return http
      .put(`${CAMPAIGNS_API}/${ctx.campaignId}/content`, { content: ctx.content }, { withCredentials: true })
      .then(resp => resp.data)
  }
}


export const routes =  {
  path: '/auth',
  component: () => import('@/layouts/full-page/FullPage.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        beforeResolve (_routeTo, _routeFrom, next) {
          // If the user is already logged in
          if (store.getters['auth/loggedIn']) {
            next()
          } else {
            // Continue to the login page
            next()
          }
        }
      }
    },
    {
      path: 'management',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: 'login',
          name: 'management-login',
          component: () => import('@/views/authentication/Login.vue')
        }
      ]
    },
    {
      path: 'forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue')
    },
    {
      path: 'reset-password/:code',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue')
    }
  ]
}
import http from '@/axios'
const ATTENDEE_REPORT_API = `${process.env.VUE_APP_LEADS_API}/reports/attendees`
const LEAD_REPORT_API = `${process.env.VUE_APP_LEADS_API}/reports/leads`
const META_REPORT_API = `${process.env.VUE_APP_LEADS_API}/reports/meta_data`

export const state = {}

export const mutations = {}

export const getters = {}

export const actions = {
  fetchReport ({}, ctx) {
    const queryParams = []
    const params = {}

    // Range
    if (ctx.range) {
      params['range'] = ctx.range
    }

    // Facets
    if (ctx.facets && ctx.facets.length > 0) {
      queryParams.push(`facets=${ctx.facets.join(',')}`)
    }

    // Group
    if (ctx.group_by) {
      queryParams.push(`group_by=${ctx.group_by}`)
    } else if (ctx.group) {
      params['group'] = ctx.group
    }

    // Match Query
    for (const key in ctx.match) {
      if (ctx.match[key]) {
        queryParams.push(`${key}=${ctx.match[key]}`)
      }
    }

    // Sorting
    if (ctx.sort) {
      params['sort'] = ctx.sort
    }

    const url = `${ATTENDEE_REPORT_API}?${queryParams.join('&')}`
    return http
      .post(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
  },

  fetchAttendeeReport ({rootState, state, commit}, ctx) {
    const queryParams = ['group_by=channel_id']

    for (const key in ctx) {
      if (ctx[key]) {
        queryParams.push(`${key}=${ctx[key]}`)
      }
    }

    const url = `${ATTENDEE_REPORT_API}?${queryParams.join('&')}`

    return http
      .get(`${url}`, { withCredentials: true })
      .then(response => response.data)
  },

  fetchAttendeeCount ({}, ctx) {
    const queryParams = {
      account_id: ctx.accountId
    }

    for (const key in ctx.params) {
      if (ctx.params[key]) {
        queryParams[key] = ctx.params[key]
      }
    }

    const url = `${ATTENDEE_REPORT_API}/count`
  
    return http
      .post(url, queryParams, { withCredentials: true })
      .then(response => response.data)
  },

  fetchLeadReport ({}, ctx) {
    const queryParams = []
    const params = {}

    // Match Query
    for (const key in ctx.match) {
      if (ctx.match[key]) {
        queryParams.push(`${key}=${ctx.match[key]}`)
      }
    }

    // Facets
    if (ctx.facets && ctx.facets.length > 0) {
      queryParams.push(`facets=${ctx.facets.join(',')}`)
    }

    // Range
    if (ctx.range) {
      params['range'] = ctx.range
    }

    // Group
    if (ctx.group_by) {
      queryParams.push(`group_by=${ctx.group_by}`)
    } else if (ctx.group) {
      params['group'] = ctx.group
    }

    // Sorting
    if (ctx.sort) {
      params['sort'] = ctx.sort
    }

    const url = `${LEAD_REPORT_API}?${queryParams.join('&')}`
    return http
      .post(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
  },

  fetchAttendeeMetaReport ({}, ctx) {
    const queryParams = []
    const params = {}

    // Match Query
    for (const key in ctx.match) {
      if (ctx.match[key]) {
        queryParams.push(`${key}=${ctx.match[key]}`)
      }
    }

    // Facets
    if (ctx.facets && ctx.facets.length > 0) {
      queryParams.push(`facets=${ctx.facets.join(',')}`)
    }

    // Range
    if (ctx.range) {
      params['range'] = ctx.range
    }

    // Group
    if (ctx.group_by) {
      queryParams.push(`group_by=${ctx.group_by}`)
    } else if (ctx.group) {
      params['group'] = ctx.group
    }

    // Sorting
    if (ctx.sort) {
      params['sort'] = ctx.sort
    }

    const url = `${META_REPORT_API}?${queryParams.join('&')}`
    return http
      .post(`${url}`, params, { withCredentials: true })
      .then(response => response.data)
  }
}

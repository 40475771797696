export const routes =  {
  path: '/events',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: '',
      name: 'events-index',
      component: () => import('@/views/events/Index.vue')
    },
    {
      path: ':event_id',
      component: () => import('../../layouts/main/Blank.vue'),
      children: [
        {
          path: '',
          name: 'events-show',
          component: () => import('@/views/events/Show.vue')
        },
        {
          path: 'tools',
          component: () => import('../../layouts/main/Blank.vue'),
          children: [
            {
              path: 'name-tags',
              name: 'events-name-tags',
              component: () => import('@/views/events/tools/NameTags.vue')
            }
          ]
        }
      ]
    }
  ]
}
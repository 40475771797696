export const routes =  {
  path: '/campaigns',
  component: () => import('../../layouts/main/Main.vue'),
  children: [
    {
      path: '',
      name: 'campaign-index',
      component: () => import('../../views/campaigns/Index.vue')
    },
    {
      path: 'new',
      name: 'campaign-new',
      component: () => import('../../views/campaigns/New.vue')
    },
    {
      path: ':id',
      name: 'campaign',
      component: () => import('../../layouts/main/Blank.vue'),
      children: [
        {
          path: '',
          name: 'campaign-view',
          component: () => import('../../views/campaigns/view/View.vue')
        },
        {
          path: 'tools',
          component: () => import('../../layouts/main/Blank.vue'),
          children: [
            {
              path: 'name-tags',
              name: 'campaign-events-name-tags',
              component: () => import('@/views/campaigns/tools/NameTags.vue')
            }
          ]
        },
        {
          path: 'analytics',
          name: 'campaign-analytics',
          component: () => import('@/views/pages/Analytics.vue')
        }
      ]
    }
  ]
}
